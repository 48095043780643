html,
body {
  background-color: #fafafa !important;
}
.divider {
  flex-shrink: 0 !important;
  border-top: 0px solid rgba(0, 0, 0, 0.12) !important;
  border-right: 0px solid rgba(0, 0, 0, 0.12) !important;
  border-left: 0px solid rgba(0, 0, 0, 0.12) !important;
  background-color: transparent !important;
  height: 0.0625rem;
  border-bottom: none !important;
  opacity: 0.25;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.5),
    rgba(52, 71, 103, 0)
  ) !important;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
